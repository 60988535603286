<template>
  <div class="blockWrapper">
    <template v-if="type === 'input'">
      <div style="width: 100%">
        <a-tooltip
          placement="right"
        >
          <template slot="title">
            <h3 class="informationText">{{ informationText }}</h3>
          </template>
          <label :for="label">
            <p class="label">{{ label }}</p>
          </label>
          <a-icon type="info-circle" style="color: black"/>
        </a-tooltip>
        <a-form-item
        >
          <a-input
            size="large"
            :id="label"
            @blur="blurHandler"
            v-model="inputValue"
          />
        </a-form-item>
      </div>
      <div class="unit">
        {{ unit }}
      </div>
    </template>
    <template v-if="type === 'checkbox'">
      <div class="checkboxWrapper">
        <a-tooltip
          placement="right"
        >
          <template slot="title">
            <h3 class="informationText">{{ informationText }}</h3>
          </template>
          <label :for="label">
            <p class="label">{{ label }}</p>
          </label>
          <a-icon type="info-circle" style="color: black"/>
        </a-tooltip>
        <div style="height: 40px; display: flex; align-items: center">
          <a-checkbox
            @change="$emit('checkboxHandler', $event)"
            :id="label"
            v-model="inputValue"
          >
            Ja
          </a-checkbox>
        </div>
      </div>
    </template>
    <template v-if="type === 'btnInput'">
      <div style="height: 100%">
        <label :for="label">
          <p class="label">{{ label }}</p>
        </label>
        <div class="inputWithOperators"
             :class="{hasError: validation.$invalid}"
        >
          <button
            class="mathOperatorWrapper"
            @click="buttonHandler('minus', name)"
          >
            <a-icon type="minus"/>
          </button>
          <a-input
            size="large"
            :id="label"
            @blur="blurHandler"
            v-model="inputValue"
          />
          <button
            class="mathOperatorWrapper"
            @click="buttonHandler('plus', name)"
          >
            <a-icon type="plus"/>
          </button>
        </div>
      </div>
    </template>
    <template v-if="type === 'radioBtn'">
      <div class="radioBtnWrapper">
        <p class="label">{{ label }}</p>
        <div style="height: 40px; display: flex; align-items: center">
          <a-radio-group name="radioGroup" default-value="NL" @change="countryHandler">
            <a-radio value="NL">
              NL
            </a-radio>
            <a-radio value="BE">
              BE
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { Radio } from 'ant-design-vue';

export default {
  name: "CalculatorInput",
  components: {
    ARadio: Radio,
    ARadioGroup: Radio.Group,
  },
  props: {
    label: String,
    unit: String,
    informationText: String,
    type: String,
    value: [Number, Boolean],
    validation: Object,
    name: String,
    checkboxHandler: Function,
    shouldntDecimal: Boolean
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    inputValue(newValue, oldValue) {
      let replacedComma = (newValue + '').replace(/,/g, '.');
      let regexp = /^\d*[.,]?\d{0,2}$/
      if (this.shouldntDecimal) regexp = /^\d*$/
      if (regexp.test(replacedComma) && +replacedComma <= (this.name === 'grossWeight' ? 10000 : 2000)) {
        this.$emit('input', this.name === 'cbm' ? +replacedComma : (+replacedComma || 1), this.name)
      } else {
        this.inputValue = this.value
      }
    },
    value(newValue) {
      this.inputValue = newValue
    }
  },
  methods: {
    blurHandler() {
      this.inputValue = ('' + this.inputValue).replace(/[.,]+$/, '')
      if (+this.inputValue <= 0 && this.name !== 'cbm') {
        this.inputValue = 1
      }
      if (this.name === 'cbm' && +this.inputValue <= 0) {
        this.inputValue = 0
      }
      if (this.shouldntDecimal) {
        this.inputValue = Math.round(+this.inputValue)
      }
    },
    buttonHandler(operator, inputName) {
      this.$emit('button', {operator, inputName})
    },
    countryHandler(event) {
      this.$emit('radioChange', event.target.value)
    }
  }
}
</script>

<style scoped lang="scss">
.blockWrapper {
  max-width: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.label {
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  padding-right: 7px;
  margin-bottom: 7px;
  display: inline-block;
}

.informationText {
  color: rgb(255, 255, 255);
  padding: 5px 0;
  font-size: 12px;
  margin-bottom: 0;
}

.unit {
  margin-left: 7px;
  font-size: 15px;
  color: rgb(96, 125, 139);
  line-height: 28px;
}

.inputWithOperators {
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 24px;
  input {
    border: none;
    background: transparent;
    outline: none;
    height: 40px;
  }
}
.inputWithOperators.hasError {
  border-color: rgb(245, 34, 45);
}

.mathOperatorWrapper {
  cursor: pointer;
  width: 70px;
  background: transparent;
  padding: 7px;
  border: none;
  border-right: 1px solid #d9d9d9;
}

.mathOperatorWrapper ~ .mathOperatorWrapper {
  border-right: none;
  border-left: 1px solid #d9d9d9;
}

.checkboxWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}


</style>
