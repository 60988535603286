<template>
  <div>
    <div>
      <a-alert
        style="margin-bottom: 30px"
        type="success"
        @close="cbmBannerHandler"
        closable
        v-if="showCbmBanner && cbm.name > 10"
      >
        <template slot="message">
          Vanaf een volume van boven de 10 CBM kan je een op maat zeevrachttarief ontvangen. Neem contact met ons op door te mailen naar
          <a href="mailto:contact@import4you.nl">contact@import4you.nl</a>
        </template>
      </a-alert>
    </div>
    <h1>
      Wat zijn jouw transportkosten?
    </h1>
    <div class="switch">
      <a-radio-group
        :default-value="false"
        size="large"
        :value="isSea.name"
        style="margin-bottom: 20px; margin-right: 50px"
        @change="switchHandler"
      >
        <a-radio-button :value="false">
          Luchtvracht
        </a-radio-button>
        <a-radio-button :value="true">
          Zeevracht
        </a-radio-button>
      </a-radio-group>
      <a-radio-group
        :value="warehouse.name"
        size="large"
        @change="switchWarehouse"
        style="margin-bottom: 20px"
      >
        <a-radio-button value="Shenzhen">
          Shenzhen
        </a-radio-button>
        <a-radio-button value="Shanghai">
          Shanghai
        </a-radio-button>
        <a-radio-button 
          v-if="isSea.name"
          value="Ningbo" 
        >
          Ningbo
        </a-radio-button>
        <a-radio-button 
          v-if="isSea.name"
          value="Andere haven" 
          style="margin-left: 10px"
        >
          Andere haven
        </a-radio-button>
      </a-radio-group>
    </div>
    <div
      v-if="warehouse.name !== 'Andere haven'"
      class="calculator"
    >
      <div class="calculatorInputsWrapper">
        <CalculatorInput
          :value="length.name"
          @input="inputHandler"
          label="Lengte"
          name="length"
          unit="CM"
          informationText="Afmetingen van één doos!"
          type="input"
          :validation="$v.length.name"
        />
        <CalculatorInput
          label="Aantal dozen"
          :shouldntDecimal="true"
          :value="quantityBoxes.name"
          name="quantityBoxes"
          @input="inputHandler"
          type="btnInput"
          :validation="$v.quantityBoxes.name"
          @button="operatorsHandler"
        />
        <CalculatorInput
          label="Aantal HS-codes"
          :shouldntDecimal="true"
          :value="hsCodes.name"
          name="hsCodes"
          @input="inputHandler"
          type="btnInput"
          :validation="$v.hsCodes.name"
          @button="operatorsHandler"
        />
        <CalculatorInput
          :value="width.name"
          @input="inputHandler"
          label="Breedte"
          name="width"
          unit="CM"
          informationText="Afmetingen van één doos!"
          type="input"
          :validation="$v.width.name"
        />
        <CalculatorInput
          :value="height.name"
          @input="inputHandler"
          label="Hoogte"
          name="height"
          unit="CM"
          type="input"
          informationText="Afmetingen van één doos!"
          :validation="$v.height.name"
        />
        <CalculatorInput
          v-if="isSea.name"
          :value="cbm.name"
          @input="inputHandler"
          label="CBM"
          name="cbm"
          type="input"
          informationText="Er geldt een minimum van 1 CBM (m³)"
          :validation="$v.cbm.name"
        />
        <CalculatorInput
          v-if="!isSea.name"
          :value="grossWeight.name"
          @input="inputHandler"
          label="Bruto gewicht"
          name="grossWeight"
          unit="KG"
          informationText="Dus wat je zending echt weegt als je het op een weegschaal zou zetten."
          type="input"
          :validation="$v.grossWeight.name"
        />
        <CalculatorInput
          :value="isDangerous.name"
          label="Gevaarlijke goederen"
          name="isDangerous"
          informationText="Vallen jouw goederen onder gevaarlijke goederen?"
          type="checkbox"
          @checkboxHandler="checkboxHandler($event, 'isDangerous')"
          :validation="$v.isDangerous.name"
        />
        <CalculatorInput
          label="Land van aflevering"
          type="radioBtn"
          @radioChange="radioHandler"
        />
        <CalculatorInput
          v-if="isSea.name"
          :value="fobKosten.name"
          label="FOB kosten"
          name="fobKosten"
          informationText="Als je EXW/FCA met je leverancier hebt afgesproken, dienen wij de FOB voor jou te regelen. De FOB kosten zie je hieronder vermeldt"
          type="checkbox"
          @checkboxHandler="checkboxHandler($event, 'fobKosten')"
        />
      </div>
      <div class="calculator__current-month">
        <span>Maand: </span> {{ months[new Date().getMonth()] }}
      </div>
    </div>
    <div
      v-if="warehouse.name !== 'Andere haven'"
      class="calculatorOutput"
    >
      <CalculatorOutput
        :data="{
          length: +length.name,
          width: +width.name,
          height: +height.name,
          grossWeight: +grossWeight.name,
          quantityBoxes: +quantityBoxes.name,
          hsCodes: +hsCodes.name,
          isDangerous: +isDangerous.name,
          isSea: +isSea.name,
          cbm: +cbm.name,
          warehouse: warehouse.name,
          country: country.name,
          priceConstants: priceConstants,
          fobKosten: +fobKosten.name
        }"
      />
    </div>
    <div 
      v-else
      class="customShippingAddress"
    >
      <p>Voor andere havens gelden andere tarieven, neem contact op met <a href="mailto:contact@import4you.nl">contact@import4you.nl</a> om de offerte aan te vragen.</p>
      <p>Vermeld hierbij het volgende:</p>
      <ul v-for="option in customShippingAddressDescriptionList" :key="option">
        <li>{{ option }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import CalculatorInput from "@/components/dashboard/calculator/CalculatorInput";
import {decimal, required} from "vuelidate/lib/validators";
import CalculatorOutput from "@/components/dashboard/calculator/CalculatorOutput";
import {customMaxValue, isDecimalWithDotOrComma} from "@/customValidators/validators";
import { Radio } from 'ant-design-vue';

export default {
  name: "Calculator",
  components: {
    CalculatorInput,
    CalculatorOutput,
    ARadio: Radio,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
  },
  data() {
    return {
      months: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
      length: {
        name: 1
      },
      width: {
        name: 1
      },
      height: {
        name: 1
      },
      grossWeight: {
        name: 1
      },
      quantityBoxes: {
        name: 1
      },
      hsCodes: {
        name: 1
      },
      cbm: {
        name: 0
      },
      isDangerous: {
        name: false
      },
      isSea: {
        name: false
      },
      warehouse: {
        name: 'Shenzhen'
      },
      country: {
        name: 'NL'
      },
      fobKosten: {
        name: false
      },
      priceConstants: {},
      showCbmBanner: false,
      customShippingAddressDescriptionList: [
        'Haven, Land', 
        'Totaal volume',
        'Aantal dozen',
        'Type goederen',
        'Datum dat goederen gereed zijn',
        'Afgesproken incoterm met leverancier',
        'Stad, Land van bestemming'
      ],
    }
  },
  mounted() {
    if (localStorage.getItem('showCbmBanner') === 'false') return
    if (!localStorage.getItem('showCbmBanner') || localStorage.getItem('showCbmBanner') === 'true') {
      this.showCbmBanner = true
      localStorage.setItem('showCbmBanner', 'true')
    }
    this.getPriceConstants()
  },
  watch: {
    'cbm.name' (num) {
      if (num > 10 && this.showCbmBanner) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
  },
  methods: {
    operatorsHandler({operator, inputName}) {
      if (operator === 'minus') {
        if (this[inputName].name === 1) return
        this[inputName].name = +this[inputName].name - 1
      } else {
        if (this[inputName].name >= 2000) return
        this[inputName].name = +this[inputName].name + 1
      }
    },
    cbmBannerHandler() {
      localStorage.setItem('showCbmBanner', 'false')
      this.showCbmBanner = false
    },
    inputHandler(newValue, inputName) {
      this[inputName].name = newValue
    },
    checkboxHandler(e, field) {
      const checked = e.target.checked;
      this[field].name = checked;
    },
    switchHandler(e) {
      this.isSea.name = !this.isSea.name
      this.cbm.name = 0
      this.grossWeight.name = 0
      this.warehouse.name = 'Shenzhen';
    },
    switchWarehouse({ target: { value } }) {
      this.warehouse.name = value;
    },
    radioHandler(value) {
      this.country.name = value
    },
    async getPriceConstants() {
      try {
        let res = await fetch(`${process.env.VUE_APP_API_URL}/api/v1/delivery/constants/list`, {
          method: 'GET'
        })
        if (res.ok) {
          this.priceConstants = await res.json()
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  validations: {
    length: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    width: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    height: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    grossWeight: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    quantityBoxes: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    cbm: {
      name: {
        decimal,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    },
    isDangerous: {
      name: {}
    },
    hsCodes: {
      name: {
        required,
        isDecimalWithDotOrComma,
        customMaxValue: customMaxValue(2000),
      }
    }
  }
}
</script>

<style scoped lang="scss">
.calculator {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  &__current-month {
    font-size: 40px;
    font-weight: 600;
    margin-right: 20px;
    color: #FFA300;
    span {
      color: black;
    }
  }
}
.calculatorInputsWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
}

.switch {
  padding: 10px 0 20px 0;
}
.customShippingAddress {
  font-weight: 700;
  font-size: 18px;
}
</style>
