<template>
  <div>
    <div style="margin-top: 20px">
      <div class="itemRow">
        <div class="itemBlock" v-if="!this.data.isSea">
          <label>
            <p class="label">Volumegewicht</p>
          </label>
          <div class="count">
            <animated-number
              :value="volumeWeight"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
            kg
          </div>
        </div>
        <div class="itemBlock">
          <label>
            <p class="label">CBM</p>
          </label>
          <div class="count">
            <animated-number
              :value="cbm"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock">
          <label>
            <p class="label">Aantal HS codes</p>
          </label>
          <div class="count">
            <animated-number
              :value="this.data.hsCodes"
              :formatValue="formatValueToInt"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock" v-if="!this.data.isSea">
          <a-tooltip
            placement="right"
          >
            <template slot="title">
              <h3 class="informationText">Dit is het gewicht waarvoor je betaalt, dus het volumegewicht of het
                bruto gewicht (welke is groter?)</h3>
            </template>
            <label>
              <p class="label">Belastbaar gewicht</p>
            </label>
            <a-icon type="info-circle" style="color: black"/>
          </a-tooltip>
          <div class="count">
            <animated-number
              :value="loadableWeight"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
            kg
          </div>
        </div>
      </div>
      <div class="itemRow itemRow--beforeLast">
        <div 
          v-if="data.isSea && data.fobKosten"
          class="itemBlock"
        >
          <label>
            <p class="label">
              FOB kosten
                <a-tooltip 
                  v-if="data.warehouse === 'Shanghai' || data.warehouse === 'Shenzhen'" 
                  placement="right"
                >
                  <template slot="title">
                    <ul v-if="data.warehouse === 'Shanghai'">
                      <li
                        v-for="text in fobNotification.shanghai" 
                        :key="text" 
                        class="informationText"
                      >
                        {{ text }}
                      </li>
                    </ul>

                    <ul v-if="data.warehouse === 'Shenzhen'">
                      <li
                        v-for="text in fobNotification.shenzhen" 
                        :key="text" 
                        class="informationText"
                      >
                        {{ text }}
                      </li>
                    </ul>

                    <!-- <ul v-else>
                      <li
                        v-for="text in fobNotification.ningbo" 
                        :key="text" 
                        class="informationText"
                      >
                        {{ text }}
                      </li>
                    </ul> -->
                  </template>
                  <a-icon type="info-circle" style="color: black"/>
                </a-tooltip>
            </p>
          </label>
          <div class="count">
            €
            <animated-number
              :value="fobKostenPayment"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock">
          <label>
            <div v-if="this.data.isSea">
              <p class="label">
                Prijs per CBM
                <a-tooltip placement="right">
                  <template slot="title">
                    <span class="informationText">Wij hanteren vaste tarieven per maand, de verzenddatum van het schip is hier van toepassing. Er geldt een mimimum van 1 CBM (m³)</span>
                  </template>
                  <a-icon type="info-circle" style="color: black"/>
                </a-tooltip>
              </p>
            </div>
            <div v-else>
              <p class="label" v-if="data.warehouse === 'Shanghai'">Geschatte kosten per kilogram
                <a-tooltip
                  placement="right"
                >
                  <template slot="title">
                    <span class="informationText">De tarieven vanuit Shanghai zijn aan verandering onderhevig. Dit is meest accurate indicatie.</span>
                  </template>
                  <a-icon type="info-circle" style="color: black"/>
                </a-tooltip>
              </p>
              <p class="label" v-else>Prijs per kilogram
                <a-tooltip
                  placement="right"
                >
                  <template slot="title">
                    <span class="informationText">Wij hanteren maandelijkse tarieven, hierop is de vluchtdatum van het vliegtuig van toepassing.</span>
                  </template>
                  <a-icon type="info-circle" style="color: black"/>
                </a-tooltip>
              </p>
            </div>
          </label>
          <div class="count">
            €
            <animated-number
              :value="pricePerKilo"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock">
          <label>
            <p class="label" v-if="data.isSea">Zeevrachtkosten China naar Nederland</p>
            <p class="label" v-else>Luchtvrachtkosten China naar Nederland</p>
          </label>
          <div class="count">
            €
            <animated-number
              :value="chinaNL"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock">
          <a-tooltip
            placement="right"
          >
            <template slot="title">
              <h3 class="informationText">
                De binnenlandse verzendkosten zijn afhankelijk van het totaal aantal pallets.
                Deze palletkosten staan gespecificeerd in onze Handleiding Zeevracht
              </h3>
            </template>
            <label>
              <p class="label">Uitleveringskosten</p>
            </label>
            <a-icon type="info-circle" style="color: black" v-if="data.isSea"/>
          </a-tooltip>
          <div class="count">
            €
            <animated-number
              :value="nl"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
        <div class="itemBlock">
          <label>
            <p class="label">Inklaringskosten</p>
          </label>
          <div class="count">
            €
            <animated-number
              :value="customClearance"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
      </div>
      <div class="itemRow itemRow--last">
        <div class="itemBlock">
          <label>
            <p class="label">Totale kosten</p>
          </label>
          <div class="count">
            €
            <animated-number
              :value="totalCost"
              :formatValue="formatValue"
              :duration="animationDuration"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimatedNumber from "animated-number-vue";

const NL = {
  1: 77,
  2: 122,
  3: 168,
  4: 202,
  5: 225,
  6: 247,
  7: 282,
  8: 304,
  9: 327,
  10: 353,
  11: 365,
  12: 379,
  13: 390,
  14: 405,
  15: 420
}

const BE = {
  1: 86,
  2: 134,
  3: 182,
  4: 214,
  5: 245,
  6: 276,
  7: 304,
  8: 344,
  9: 360,
  10: 384,
  11: 398,
  12: 415,
  13: 428,
  14: 445,
  15: 460
}

const SHANGHAI_COSTS = {
  handling: 25,
  docs: 40,
  ens: 20,
  vgm: 20,
  preManifestFee: 15,
  cleareance: 18,
  telexReleaseFee: 15,
};
const SHANGHAI_OTHER_COSTS = Object.values(SHANGHAI_COSTS).reduce((accum, value) => { return accum + value }, 0);
const SHANGHAI_CBM_RATE = 12;
const SHANGHAI_MIN_CBM_ORDER = 3;
const SHANGHAI_MIN_CBM_ORDER_COST = 12;
const SHANGHAI_REGULAR_CBM_COST = 4;

const SHENZHEN_OTHER_COSTS = 229;
const SHENZHEN_CBM_RATE = 23.04;

const NINGBO_COSTS = {
  handling: 25,
  docs: 40,
  ens: 20,
  vgm: 20,
  preManifestFee: 15,
  cleareance: 18,
  telexReleaseFee: 15,
  warehouseDocFee: 15,
};

const NINGBO_OTHER_COSTS = Object.values(NINGBO_COSTS).reduce((accum, value) => { return accum + value }, 0);
const NINGBO_CBM_RATE = 12;
const NINGBO_MIN_CBM_ORDER = 3;
const NINGBO_MIN_CBM_ORDER_COST = 12;
const NINGBO_REGULAR_CBM_COST = 4;

export default {
  name: "CalculatorOutput",
  components: {
    AnimatedNumber
  },
  props: {
    data: Object,
  },
  data() {
    return {
      animationDuration: 800,
      fobNotification: {
        shanghai: [
          'CFS USD 12/WM', 
          'Handling USD 25/Shipment', 
          'Docs USD 40/Set', 
          'ENS USD 20/Entry',
          'VGM USD 20/Entry',
          'Pre Manifest Fee USD 15/Entry',
          'Clearance USD 18/Entry',
          'Telex release Fee USD 15/BL',
          'In/Out Fee USD 4/CBM',
        ],
        shenzhen: [
          'CFS USD 23,04/WM',
          'BL Fee 65/BL',
          'Handling Charge USD 30/BL',
          'Telex Release Fee USD 30/BL',
          'ENS Fee USD 30/BL',
          'Custom Clearance Fee USD 54/Set',
          'VGM USD 20/BL'
        ],
        ningbo: [
          // 'CFS USD 23,04/WM',
          // 'BL Fee 65/BL',
          // 'Handling Charge USD 30/BL',
          // 'Telex Release Fee USD 30/BL',
          // 'ENS Fee USD 30/BL',
          // 'Custom Clearance Fee USD 54/Set',
          // 'VGM USD 20/BL'
        ]
      }
    }
  },
  computed: {
    price() {
      if (this.data.warehouse === 'Shanghai') {
        return +this.data.priceConstants.air_ppk_shanghai || 0
      }

      if (this.data.warehouse === 'Ningbo') {
        return +this.data.priceConstants.sea_ppc_ningbo || 0
      }
      
      return +this.data.priceConstants.air_ppk_schenzhen || 0
    },
    hsCodes() {
      if (this.data.isSea && this.data.hsCodes >= 1) return this.data.hsCodes - 1
      if (this.data.hsCodes >= 2) return this.data.hsCodes - 2
      return 0
    },
    volumeWeight() {
      if (this.data.isSea && this.data.cbm) {
        return Math.ceil((this.data.cbm / 0.006).toFixed(2))
      } else {
        return Math.ceil(+((this.data.length * this.data.width * this.data.height) * this.data.quantityBoxes / 6000).toFixed(2))
      }
    },
    loadableWeight() {
      if (this.data.isSea) return this.volumeWeight
      return (this.data.grossWeight > this.volumeWeight ? +this.data.grossWeight : +this.volumeWeight).toFixed(2)
    },
    pricePerKilo() {
      if (this.data.isSea) {
        return this.data.warehouse === 'Ningbo' ? +this.data.priceConstants.sea_ppc_ningbo || 0 : +this.data.priceConstants.sea_ppc || 0 
      }
      if (this.data.isDangerous) return (this.price).toFixed(2)
      return this.price.toFixed(2)
    },
    cbm() {
      if (this.data.isSea && this.data.cbm) return (this.data.cbm).toFixed(2)
      return (this.volumeWeight * 0.006).toFixed(2)
    },
    customClearance() {
      let res = this.data.hsCodes < 3 ? 20 : (20 + (5 * (this.hsCodes)))
      if (this.data.isSea) {
        res = this.data.hsCodes < 2 ? 27.50 : (27.50 + (12.5 * (this.hsCodes)))
      }
      return (
        res.toFixed(2)
      )
    },
    chinaNL() {
      if (this.data.isSea) {
        return this.cbm > 0 && this.cbm < 1 ? this.pricePerKilo : this.cbm * this.pricePerKilo;
      }
      const biggest = this.data.grossWeight > this.volumeWeight ? +this.data.grossWeight : +this.volumeWeight
      return (biggest * this.pricePerKilo).toFixed(2)
    },
    nl() {
      if (this.data.isSea) {
        let currentCbm;
        if (this.data.cbm) {
          currentCbm = Math.ceil(this.data.cbm / 1.2)
        } else {
          currentCbm = Math.ceil((this.volumeWeight * 0.006 / 1.2).toFixed(2))
        }
        let currentCountryOptions;
        if (this.data.country === 'NL') {
          currentCountryOptions = NL;
        } else {
          currentCountryOptions = BE;
        }
        if (currentCbm === 0) {
          return 0
        } else if (currentCbm >= 15) {
          return currentCountryOptions[15]
        } else {
          return currentCountryOptions[currentCbm]
        }

      }
      let i = 1;
      let sum = 0
      if (this.data.country === 'BE') {
        while (i <= +this.data.quantityBoxes) {
          if (i === 1) sum += 10.75
          if (i === 2) sum += 9.75
          if (i >= 3) sum += 8.75
          i++;
        }
      } else {
        while (i <= +this.data.quantityBoxes) {
          if (i === 1) sum += 7.75
          if (i === 2) sum += 6.75
          if (i >= 3) sum += 5.75
          i++;
        }
      }

      return sum.toFixed(2)
    },
    fobKostenPayment () {
      const shanghai = this.data.warehouse === 'Shanghai';
      const shenzhen = this.data.warehouse === 'Shenzhen';
      const ningbo = this.data.warehouse === 'Ningbo';
      const countOfCbm = this.cbm && this.cbm < 1 ? 1 : +this.cbm;
      if (!this.data.fobKosten || !countOfCbm || !this.data.isSea) return 0;

      if (shanghai) {
        const paymentForCbmCount = countOfCbm <= SHANGHAI_MIN_CBM_ORDER ? SHANGHAI_MIN_CBM_ORDER_COST : countOfCbm * SHANGHAI_REGULAR_CBM_COST; 
        return (((SHANGHAI_CBM_RATE * countOfCbm + paymentForCbmCount + SHANGHAI_OTHER_COSTS) / this.data.priceConstants.usd_to_euro_rate) || 0);
      }

      if (shenzhen) {
        return (((SHENZHEN_CBM_RATE * countOfCbm + SHENZHEN_OTHER_COSTS) / this.data.priceConstants.usd_to_euro_rate) || 0);
      }

      if (ningbo) {
        const paymentForCbmCount = countOfCbm <= NINGBO_MIN_CBM_ORDER ? NINGBO_MIN_CBM_ORDER_COST : countOfCbm * NINGBO_REGULAR_CBM_COST; 
        return (((NINGBO_CBM_RATE * countOfCbm + paymentForCbmCount + NINGBO_OTHER_COSTS) / this.data.priceConstants.usd_to_euro_rate) || 0);
      }
    },
    totalCost() {
      return (+this.chinaNL + +this.customClearance + +this.nl + +this.fobKostenPayment).toFixed(2);
    }
  },
  methods: {
    formatValue(value) {
      return (Number(value) || 0).toFixed(2)
    },
    formatValueToInt(value) {
      return (Number(value) || 0).toFixed()
    },
  }
}
</script>

<style scoped lang="scss">
.label {
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  padding-right: 7px;
  margin-bottom: 7px;
  display: inline-block;
}

.informationText {
  color: rgb(255, 255, 255);
  padding: 5px 0;
  font-size: 12px;
  margin-bottom: 0;
}

.itemRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(225, 225, 225, 1);

  &--last {
    border: none;
  }

  &--beforeLast {
    border: none;
    position: relative;

    &::after {
      width: 20%;
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      background-color: rgba(225, 225, 225, 1);
    }
  }
}

.itemBlock {
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.count {
  font-weight: bold;
  font-size: 32px;
  color: #FFA300;
  line-height: 1;
  text-align: center;
}

</style>
